import { Component, OnInit } from '@angular/core'

import {MatDialog} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalConstants } from '../global-constants';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  constructor(public dialog: MatDialog) { }
 logedin:boolean;
 cuname:String;
  ngOnInit(): void {
    $(document).ready(function () {
      if ($(window).width() < 767) {
      $('.start-header .navurl').click(function (e) { 
        e.preventDefault();
        $('.navbar-toggler').trigger('click');
      });
    }
    });
    this.logedin=false;
    this.checkLoggedin();
  }
  public logout(){
    localStorage.removeItem("customer_id"); 
    localStorage.removeItem("customer_name");
    window.location.replace("https://yodertoter.com/");
  }
  public checkLoggedin(){
    if (localStorage.getItem("customer_id") === null) {
      this.logedin=false;
    }
    else{
      this.logedin=true;
      this.cuname=localStorage.getItem("customer_name");
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(DialogContentExampleDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

}
openDialog2() {
  const dialogRef = this.dialog.open(DialogContentExampleDialog2);

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });

}


}
@Component({
  selector: 'customer-login',
  templateUrl: 'customer-login.html',
  styleUrls: ['./header.component.less']
})
export class DialogContentExampleDialog {
  hide = true;

  email = new FormControl('', [Validators.required, Validators.email]);
  constructor(private route:Router,private http: HttpClient) {
   
   }
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }
    

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  public login(phone:any,password:any){
    console.log("login clicked");
    if(phone==""|| phone==null || phone==undefined || password==""|| password==null || password==undefined  ){
      $(".errormod").html(" All fields are required");
      $(".errormod").show();
    }
    else{
      $(".errormod").hide();

      let logindat = {
        data: [
          {
            phone: phone,
            password: password,
          },
        ],
      };
      const headers = { 'Content-type': 'application/json'};
    const body = {
      title: 'Login',
      logindat: logindat,
    };
    this.http.post(GlobalConstants.apiURL+'checkcustomer', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          console.log(Response);
          if(Response.status=="success"){
            localStorage.setItem('customer_id', Response.customer_id);
            localStorage.setItem('customer_name', Response.customer_name);
            window.location.reload();
          }
          else{
            $(".errormod").html("Please check phone and password");

            $(".errormod").show();
          }
         
        }


      });
    }
  }   
  public forgotPwd(phone:any){
    if(phone==""|| phone==null || phone==undefined){
      $(".errormod").html(" All fields are required");
      $(".errormod").show();
    }
    else{
      $(".errormod").hide();

     
      const headers = { 'Content-type': 'application/json'};
    const body = {
      title: 'Forgot Password',
      phone: phone,
    };
    this.http.post(GlobalConstants.apiURL+'resetpassword', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          console.log(Response);
          if(Response.status=="success"){
            Swal.fire({
              title: 'Success',
              text: "We have sent you an sms with temporary password.",
              icon: 'success',
              confirmButtonText: 'OK'
            });
          }
          else{
            $(".errormod").html("Please check phone number");

            $(".errormod").show();
          }
         
        }


      });
    }
  }
  public toggleshow(){
    $('.lgndt').toggleClass('d-none');
    $('.fgdt').toggleClass('d-none');
  }
  public register(name:any,mobile:any,email:any,password:any){
    console.log("register clicked");
    if(name==""|| name==null || name==undefined || mobile==""|| mobile==null || mobile==undefined || email==""|| email==null || email==undefined || password==""|| password==null || password==undefined  ){
      $(".errormod").html(" All fields are required");
      $(".errormod").show();
    }
    else{
      $(".errormod").hide();

      let regdata = {
        data: [
          {
            name: name,
            mobile: mobile,
            email: email,
            password: password,
          },
        ],
      };
      const headers = { 'Content-type': 'application/json'};
    const body = {
      title: 'Register',
      regdata: regdata,
    };
    this.http.post(GlobalConstants.apiURL+'register', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          console.log(Response);
          if(Response.status=="success"){
            localStorage.setItem('customer_id', Response.customer_id);
            localStorage.setItem('customer_name', Response.customer_name);
            window.location.reload();
          }
          else{
            $(".errormod").html(Response.message);

            $(".errormod").show();
          }
          }


      });
    }
  }
}

@Component({
  selector: 'driver-login',
  templateUrl: 'driver-login.html',
  styleUrls: ['./header.component.less']
})
export class DialogContentExampleDialog2 {
  hide = true;

  email = new FormControl('', [Validators.required, Validators.email]);

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
}